import React, { Component } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import GlobalBanner from '../components/globalBanner'


class PostTemplate extends Component {
  render() {
    const post = this.props.data.wpPost
    const data = this.props.data;

    return (
      <Layout>
        <div className="blog-template">
          {post.featuredImage.node !== null &&
            post.featuredImage.node.localFile !== null ? (
              <GlobalBanner bgSharpImg={post.featuredImage.node.localFile} />
            ) : (
              <GlobalBanner bgSharpImg={post.featuredImage.node.sourceUrl} />
            )
          }
          <div className="ctn-main uk-padding uk-text-center uk-background-default push-up-small">
          <div dangerouslySetInnerHTML={{ __html: post.content }} width="100%" height="5000"/>
          </div> {/* End .ctn-main.uk-padding */}
        </div>
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                sizes: "(min-width: 1429px) 1920px, 100vw"
              )
            }
          }
          sourceUrl
        }
      }
    }
  }
`
