import React, { Component } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class GlobalBanner extends Component {
  render() {

    let backgroundImg = {
      backgroundImage: 'url(' + this.props.bgImg + ')'
    }

    return (
      <div className="banner global-banner">
        <div className="positioned-ctn">
          {this.props.bgSharpImg !== undefined && this.props.bgSharpImg !== false ? (
            <GatsbyImage image={getImage(this.props.bgSharpImg)} />
          ) : (
            <div className="img-ctn" style={backgroundImg}></div>
          )}
        </div> {/* End .positioned-ctn */}

        <div className="ctn-main">
          <div className="banner__content uk-text-center">
            {this.props.pageTitle !== undefined &&
              <h1 dangerouslySetInnerHTML={{ __html: this.props.pageTitle }}></h1>
            }
            {this.props.pageTitle !== undefined &&
              <p>{this.props.pageSubTitle}</p>
            }
          </div> {/* End .banner__content */}
        </div> {/* End .ctn-main */}

      </div> // End .banner.global-banner
    );
  }
}

export default GlobalBanner;